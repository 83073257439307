import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CATEGORY } from '@tokens/category.token';
import {
  CreateStrategyRequest,
  SimpleStrategyItem,
  SimpleStrategyListRequest,
  StrategyItem,
  StrategyItems,
  StrategyItemsResponse,
  StrategyListResponse,
  StrategyMatrixResponse,
  StrategyMatrixWithPresentValues,
  UpdateStrategyMatrix,
  UpdateStrategyRequest,
} from '@type/strategy.type';
import { createInjectorFn } from '@utils/create-provider';
import { joinUrls } from '@utils/urls';
import { Observable, filter, first, map, switchMap, tap } from 'rxjs';
import { ConfirmCompleteModalComponent } from 'src/app/pages/clustering/components/confirm-complete-modal/confirm-complete-modal.component';
import { CONFIRM_COMPLETE_DIALOG_TRANSLATIONS } from 'src/app/pages/clustering/components/confirm-complete-modal/translation.token';

@Injectable()
export class StrategyApiService {
  private http = inject(HttpClient);

  private category = inject(CATEGORY).asObservable;

  private modal = inject(MatDialog);

  private createInjector = createInjectorFn();

  private translate = inject(TranslateService);

  items: StrategyItems['items'] = [];

  getStrategyList(request: SimpleStrategyListRequest): Observable<StrategyListResponse> {
    const { categories, strategies, index } = environment.urls;

    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) =>
        this.http.post<StrategyListResponse>(joinUrls(categories, String(id), strategies, index), request),
      ),
    );
  }

  getStrategyById(strategyId: number): Observable<StrategyItem> {
    const { categories, strategies } = environment.urls;

    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) =>
        this.http.get<StrategyItem>(joinUrls(categories, String(id), strategies, String(strategyId))),
      ),
    );
  }

  updateStrategyMatrix(
    strategyId: number | string,
    matrix: UpdateStrategyMatrix,
    strategy: UpdateStrategyRequest,
  ): Observable<{ message: string }> {
    const { categories, strategies, update_matrix } = environment.urls;

    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) =>
        this.showConfirmDialog()
          .afterClosed()
          .pipe(
            filter(Boolean),
            map((action) => ({ id, action })),
          ),
      ),
      switchMap(({ id, action }) => {
        strategy.draft = action === 'draft';

        return this.http.post<{ message: string }>(
          joinUrls(categories, String(id), strategies, String(strategyId), update_matrix),
          { matrix, strategy },
        );
      }),
    );
  }

  createStrategy(data: CreateStrategyRequest) {
    const { categories, strategies } = environment.urls;

    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) => this.http.post<StrategyItem>(joinUrls(categories, String(id), strategies), data)),
    );
  }

  copyStrategy(strategyId: number): Observable<SimpleStrategyItem> {
    const { categories, strategies, copy } = environment.urls;

    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) =>
        this.http.post<SimpleStrategyItem>(joinUrls(categories, String(id), strategies, String(strategyId), copy), {}),
      ),
    );
  }

  deleteStrategy(strategyId: number) {
    const { categories, strategies } = environment.urls;

    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) => this.http.delete(joinUrls(categories, String(id), strategies, String(strategyId)))),
    );
  }

  getStrategyItems(strategyId: number | string): Observable<StrategyItems> {
    const { categories, strategies, items } = environment.urls;
    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) =>
        this.http
          .post<StrategyItemsResponse>(joinUrls(categories, String(id), strategies, String(strategyId), items), {})
          .pipe(
            map(({ strategy_items }) => ({
              items: strategy_items.map((si) => ({
                ...si,
                id: si.item_id,
              })),
            })),
            tap((res) => {
              this.items = res.items;
            }),
          ),
      ),
    );
  }

  getMatrix(strategyId: number, branch: number[] = []): Observable<StrategyMatrixWithPresentValues> {
    const { categories, strategies, matrix } = environment.urls;

    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) =>
        this.http
          .post<StrategyMatrixResponse>(joinUrls(categories, String(id), strategies, String(strategyId), matrix), {
            branch,
          })
          .pipe(map(({ matrix, meta: { present_values } }) => ({ matrix, presentValues: present_values }))),
      ),
    );
  }

  updateStrategy(strategyId: number | string, data: UpdateStrategyRequest) {
    const { categories, strategies } = environment.urls;

    return this.category.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) =>
        this.http.put<StrategyItem>(joinUrls(categories, String(id), strategies, String(strategyId)), data),
      ),
    );
  }

  private showConfirmDialog() {
    return this.modal.open(ConfirmCompleteModalComponent, {
      width: '800px',
      injector: this.createInjector([
        {
          provide: CONFIRM_COMPLETE_DIALOG_TRANSLATIONS,
          useValue: {
            title: this.translate.instant('strategy.title'),
            subject: this.translate.instant('strategy.publish'),
            actionList: ['strategy.save_as_draft', 'strategy.save_and_publish'],
          },
        },
      ]),
    });
  }
}
